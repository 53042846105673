* {
  padding: 0;
  margin: 0;
  font-family: paralucent-condensed, sans-serif;
}

.mobile-only {
  display: block;
}
@media (min-width: 768px) {
  .mobile-only {
    display: none;
  }
}

.desktop-only {
  display: none;
}
@media (min-width: 768px) {
  .desktop-only {
    display: block;
  }
}

a {
  color: #018f5f;
}

p {
  font-weight: 300;
}

h2 {
  background: -webkit-linear-gradient(45deg, #1d3b6d, #018f5f);
  line-height: 1;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

body {
  background-image: url("img/background.jpg");
  height: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-position: right;
}

#main-container {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  background: rgba(255, 255, 255, 0.99);
  margin: 0 auto;
  min-height: 100vh;
}
#main-container header {
  display: flex;
  flex-direction: column;
  border-bottom: 1px #eee solid;
}
@media (min-width: 768px) {
  #main-container header {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
  }
}
#main-container header .pre-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 10px;
}
#main-container header .pre-nav img {
  height: 65px;
}
#main-container header .pre-nav span {
  font-size: 2rem;
}
@media (min-width: 768px) {
  #main-container header .pre-nav span {
    display: none;
  }
}
#main-container header .main-nav {
  display: flex;
  align-items: center;
  justify-content: center;
}
#main-container header .main-nav ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  align-items: center;
  text-align: center;
  padding-bottom: 30px;
}
#main-container header .main-nav ul .lessons-toggle {
  cursor: pointer;
  font-size: 2rem;
  padding: 10px 15px;
  text-decoration: none;
  color: #888;
  background: none;
  border: 0;
}
@media (min-width: 768px) {
  #main-container header .main-nav ul .lessons-toggle {
    font-size: 1.1rem;
    transition: color 0.2s;
  }
  #main-container header .main-nav ul .lessons-toggle:hover {
    color: #333;
  }
}
#main-container header .main-nav ul .lessons-dropdown {
  padding: 15px;
  background: #fafafa;
  border-radius: 5px;
}
@media (min-width: 768px) {
  #main-container header .main-nav ul .lessons-dropdown {
    flex-direction: column;
    position: absolute;
    margin-top: 10px;
    margin-left: 10px;
  }
}
#main-container header .main-nav ul li {
  margin: 5px;
}
#main-container header .main-nav ul li.portal {
  background-image: linear-gradient(135deg, #1d3b6d, #018f5f);
  border-radius: 5px;
  transition: transform 0.2s;
  padding: 10px 0;
}
#main-container header .main-nav ul li.portal a {
  color: #eee !important;
}
#main-container header .main-nav ul li.portal:hover {
  transform: scale(1.05);
}
#main-container header .main-nav ul li a {
  font-size: 2rem;
  padding: 10px 15px;
  text-decoration: none;
  color: #888;
}
@media (min-width: 768px) {
  #main-container header .main-nav ul li a {
    font-size: 1.1rem;
    transition: color 0.2s;
  }
  #main-container header .main-nav ul li a:hover {
    color: #333;
  }
}
@media (min-width: 768px) {
  #main-container header .main-nav ul {
    display: flex;
    flex-direction: row;
    padding-bottom: 0;
  }
}
#main-container header .main-nav ul .lessons-nav {
  display: flex;
  flex-direction: column;
}

.feature-image img {
  width: 100%;
}

.page-header {
  background-size: cover;
  background-position: center;
}
.page-header h1 {
  color: white;
  text-align: center;
  padding: 30px;
  font-size: 3rem;
  font-weight: 500;
}

#callout {
  background-image: url("img/calloutbackground.jpg");
  background-size: cover;
  background-position: bottom;
  border-radius: 10px;
  width: 95%;
  margin: 50px auto;
  padding: 30px;
  box-sizing: border-box;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1024px) {
  #callout {
    flex-direction: row;
  }
}
#callout h3 {
  font-weight: 500;
  font-size: 2.5rem;
}
#callout a {
  background: white;
  color: #1d3b6d;
  text-decoration: none;
  padding: 10px 20px;
  box-sizing: content-box;
  margin: 20px;
  font-size: 1.5rem;
  font-weight: 500;
  border-radius: 5px;
  transition: transform 0.2s;
}
#callout a:hover {
  transform: scale(1.05);
}

.container-homepage h2 {
  font-size: 2.5rem;
}
.container-homepage .learn {
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.container-homepage .learn a {
  margin: 5px;
  padding: 30px 20px;
  font-size: 2rem;
  text-decoration: none;
  color: white;
  background-image: linear-gradient(135deg, #1d3b6d, #018f5f);
  background-size: cover;
  border-radius: 5px;
  transition: transform 0.2s;
}
@media (min-width: 768px) {
  .container-homepage .learn a {
    padding: 30px 50px;
    margin: 15px;
  }
}
.container-homepage .learn a:hover {
  transform: scale(1.05);
}

main {
  width: 95%;
  margin: 30px auto;
  text-align: center;
}
main .container-contact {
  display: grid;
  grid-template-columns: 1fr;
}
main .container-contact h2 {
  font-size: 1.7rem;
  margin-bottom: 5px;
}
main .container-contact p {
  margin-bottom: 30px;
  font-size: 1.3rem;
}
@media (min-width: 768px) {
  main .container-contact {
    grid-template-columns: 3fr 2fr;
    grid-gap: 30px;
  }
  main .container-contact .map {
    order: -1;
  }
  main .container-contact h2 {
    text-align: left;
  }
  main .container-contact p {
    text-align: left;
    font-size: 1.1rem;
  }
  main .container-contact iframe {
    height: 500px;
  }
}
@media (min-width: 768px) {
  main .container-about .general {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px;
  }
  main .container-about .why {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 25px;
  }
}
main .container-about .who, main .container-about .mission, main .container-about .why, main .container-about .faculty {
  margin-bottom: 40px;
}
main .container-about .why h3 {
  margin-top: 20px;
}
main .container-about h2 {
  font-size: 2rem;
  margin-top: 30px;
  margin-bottom: 30px;
}
main .container-about .independent-teachers-subtitle {
  font-size: 1.2rem;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  main .container-about .faculty {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
  }
}
main .container-about .faculty .faculty-member {
  margin-bottom: 50px;
}
main .container-about .faculty .faculty-member h3 {
  font-size: 1.5rem;
  margin-top: 5px;
}
main .container-about .faculty .faculty-member h4 {
  font-weight: 500;
  font-size: 1.3rem;
  color: #1d3b6d;
}
main .container-about .faculty .faculty-member span {
  font-size: 0.9rem;
  color: #666;
  font-weight: 300;
}
main .container-about .faculty .faculty-member h5 {
  margin-top: 10px;
  font-size: 1.3rem;
}
main .container-about .faculty .faculty-member .faculty-member-image {
  margin: 0 auto;
  width: 100%;
  height: 350px;
  background-size: cover;
  background-position: center;
}
@media (min-width: 768px) {
  main .container-about .faculty .faculty-member .faculty-member-image {
    width: 65%;
    height: 250px;
  }
}
main .container-about .faculty .faculty-member .faculty-member-image.junior {
  position: relative;
}
main .container-about .faculty .faculty-member .faculty-member-image.junior:after {
  background: #018f5f;
  line-height: 1;
  background: -webkit-linear-gradient(45deg, #1d3b6d, #018f5f);
  content: "Junior Teacher";
  font-size: 1.2rem;
  height: 36px;
  width: 36px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  top: -30px;
  right: 30px;
  color: white;
  font-weight: bold;
}
@media (min-width: 768px) {
  main .container-about .faculty .faculty-member .faculty-member-image.junior:after {
    right: -30px;
  }
}
main .container-faq h2 {
  margin-bottom: 20px;
}
main .container-faq h4 {
  margin-top: 10px;
}
main .container-faq hr {
  margin: 30px 0;
}
main .container-lessons {
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  main .container-lessons {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 2fr 1fr;
    text-align: left;
  }
  main .container-lessons .lesson-image {
    background-position: center;
    background-size: cover;
  }
}
main .container-lessons h2 {
  font-size: 2rem;
}
main .container-lessons h3 {
  margin-top: 15px;
}
main .container-lessons h4 {
  margin-bottom: 15px;
}
main .container-lessons ul {
  list-style: none;
}
@media (min-width: 768px) {
  main .container-lessons ul {
    list-style-type: square;
    padding-left: 20px;
  }
}

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: 100px;
}
.not-found a {
  padding: 10px;
  color: white;
  background: #306f6f;
  margin-top: 20px;
  display: block;
  text-decoration: none;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}

footer {
  background: #666;
  color: white;
  font-weight: 200;
  font-size: 0.8rem;
  text-align: center;
  padding: 5px;
  margin-top: auto;
  letter-spacing: 1px;
}
footer a {
  color: #ddd;
}

#link-to-portal {
  background: -webkit-linear-gradient(45deg, #1d3b6d, #018f5f);
  color: white;
  transition: transform 0.2s;
  padding: 10px;
  border-radius: 5px;
}
#link-to-portal:hover {
  transform: scale(1.05) rotate(3deg);
}
#link-to-portal a {
  color: white !important;
  font-weight: 600;
}

.coming-soon {
  position: relative;
}
.coming-soon::before {
  content: "Coming soon!";
  display: block;
  position: absolute;
  background-color: #018f5f;
  padding: 5px 10px;
  font-size: 1.5rem;
  top: -15px;
  right: -40px;
}

.container-tcs {
  text-align: left;
}
.container-tcs h2:not(:first-child) {
  margin-top: 50px;
}
.container-tcs h2 {
  display: inline-block;
}
.container-tcs p {
  font-size: 1.3rem;
  line-height: 1.3;
  margin-top: 5px;
}
.container-tcs span {
  margin-top: 25px;
  display: block;
}

