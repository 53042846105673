//breakpoints
$bp-tablet: 768px;
$bp-desktop: 1024px;

//breakpoint mixins
@mixin tablet{
  @media(min-width:$bp-tablet){@content;}
}
@mixin desktop{
  @media(min-width:$bp-desktop){@content;}
}

* {
  padding: 0;
  margin: 0;
  font-family: paralucent-condensed, sans-serif;
}

.mobile-only{
  display:block;
  @include tablet{
    display:none;
  }
}
.desktop-only{
  display:none;
  @include tablet{
    display:block;
  }
}

a{
  color:#018f5f;
}

p{
  font-weight:300;
}

h2{
  background: -webkit-linear-gradient(45deg, #1d3b6d, #018f5f);
  line-height: 1;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

body {
  background-image: url("img/background.jpg");
  height: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-position: right;
}

#main-container {
  display:flex;
  flex-direction: column;
  max-width: 1000px;
  background: rgba(255,255,255,0.99);
  margin: 0 auto;
  min-height:100vh;

  header {
    display: flex;
    flex-direction: column;
    border-bottom: 1px #eee solid;
    @include tablet {
      flex-direction: row;
      justify-content: space-between;
      padding: 0 20px;
    }

    .pre-nav {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px 0 10px;

      img {
        height: 65px;
      }

      span {
        font-size: 2rem;
        @include tablet {
          display: none;
        }
      }
    }

    .main-nav {
      display: flex;
      align-items: center;
      justify-content: center;

      ul {
        display: flex;
        flex-direction: column;
        list-style: none;
        align-items: center;
        text-align: center;
        padding-bottom: 30px;

        .lessons-toggle{
          cursor: pointer;
          font-size: 2rem;
          padding: 10px 15px;
          text-decoration: none;
          color: #888;
          background:none;
          border: 0;
          @include tablet {
            font-size: 1.1rem;
            transition: color 0.2s;

            &:hover {
              color: #333;
            }
          }
        }

        .lessons-dropdown {
          @include tablet {
            flex-direction: column;
            position: absolute;
            margin-top: 10px;
            margin-left: 10px;
          }
          padding: 15px;
          background: #fafafa;
          border-radius: 5px;
        }

        li {
          margin: 5px;

          &.portal {
            background-image: linear-gradient(135deg, #1d3b6d,#018f5f);
            border-radius: 5px;
            transition: transform 0.2s;
            padding: 10px 0;

            a {
              color: #eee !important;
            }

            &:hover {
              transform: scale(1.05);
            }
          }

          a {
            font-size: 2rem;
            padding: 10px 15px;
            text-decoration: none;
            color: #888;
            @include tablet {
              font-size: 1.1rem;
              transition: color 0.2s;

              &:hover {
                color: #333;
              }
            }
          }
        }
        @include tablet {
          display: flex;
          flex-direction: row;
          padding-bottom: 0;
        }

        .lessons-nav {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

}

.feature-image{
  img{
    width:100%;
  }
}

.page-header{
  background-size:cover;
  background-position: center;
  h1{
    color:white;
    text-align:center;
    padding:30px;
    font-size:3rem;
    font-weight:500;
  }
}

#callout{
  background-image: url('img/calloutbackground.jpg');
  background-size:cover;
  background-position: bottom;
  border-radius:10px;
  width:95%;
  margin: 50px auto;
  padding:30px;
  box-sizing: border-box;
  color:white;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include desktop{
    flex-direction: row;
  }
  h3{
    font-weight:500;
    font-size:2.5rem;
  }
  a{
    background:white;
    color:#1d3b6d;
    text-decoration: none;
    padding:10px 20px;
    box-sizing: content-box;
    margin:20px;
    font-size:1.5rem;
    font-weight:500;
    border-radius: 5px;
    transition: transform 0.2s;
    &:hover{
      transform: scale(1.05);
    }
  }
}

.container-homepage{
  h2{
    font-size:2.5rem;
  }
  .learn{
    width:100%;
    margin: 0 auto;
    justify-content: center;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;

    a{
      margin:5px;
      padding:30px 20px;
      @include tablet{
        padding:30px 50px;
        margin:15px;
      }
      font-size:2rem;
      text-decoration: none;
      color:white;
      background-image:linear-gradient(135deg, #1d3b6d,#018f5f);
      background-size:cover;
      border-radius: 5px;
      transition: transform 0.2s;
      &:hover{
        transform: scale(1.05);
      }
    }
  }
}

main {
  width:95%;
  margin:30px auto;
  text-align:center;

  .container-contact{
    display:grid;
    grid-template-columns: 1fr;
    h2{
      font-size:1.7rem;
      margin-bottom:5px;
    }
    p{
      margin-bottom:30px;
      font-size:1.3rem;
    }
    @include tablet{
      grid-template-columns: 3fr 2fr;
      grid-gap:30px;
      .map{
        order:-1;
      }
      h2{
        text-align:left
      }
      p{
        text-align:left;
        font-size:1.1rem;
      }
      iframe{
        height:500px;
      }
    }
  }

  .container-about{
    @include tablet{
      .general{
        display:grid;
        grid-template-columns: 1fr 1fr;
        grid-gap:25px;
      }
      .why{
        display:grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap:25px;
      }
    }
    .who,.mission,.why,.faculty{
      margin-bottom:40px;
    }
    .why h3{
      margin-top:20px;
    }
    h2{
      font-size:2rem;
      margin-top:30px;
      margin-bottom:30px;
    }
    .independent-teachers-subtitle{
      font-size:1.2rem;
      margin-bottom:20px;
    }
    .faculty{
      @include tablet{
        display:grid;
        grid-template-columns: 1fr 1fr;
        grid-gap:40px;
      }
      .faculty-member{

        h3{
          font-size:1.5rem;
          margin-top:5px;
        }
        h4{
          font-weight:500;
          font-size:1.3rem;
          color:#1d3b6d;
        }
        span{
          font-size:0.9rem;
          color:#666;
          font-weight:300;
        }

        h5{
          margin-top:10px;
          font-size:1.3rem;
        }
        margin-bottom:50px;
        .faculty-member-image{
          margin: 0 auto;
          width:100%;
          height:350px;
          @include tablet{
            width:65%;
            height:250px;
          }
          background-size: cover;
          background-position: center;
          &.junior{
            position:relative;
            &:after{
              background: #018f5f;
              line-height:1;
              background: -webkit-linear-gradient(45deg, #1d3b6d, #018f5f);
              content: "Junior Teacher";
              font-size:1.2rem;
              height:36px;
              width:36px;
              padding:25px;
              display:flex;
              align-items:center;
              justify-content: center;
              border-radius:100%;
              position:absolute;
              top:-30px;
              right: 30px;
              color:white;
              font-weight:bold;
              @include tablet{
                right:-30px;
              }
            }
          }
        }
      }
    }


  }

  .container-faq{
    h2{
      margin-bottom:20px;
    }
    h4{
      margin-top:10px;
    }
    hr{
      margin:30px 0;
    }
  }

  .container-lessons{
    @include tablet{
      display:grid;
      grid-gap:30px;
      grid-template-columns: 2fr 1fr;
      text-align:left;
      .lesson-image{
        background-position: center;
        background-size: cover;
      }
    }
    margin-bottom:50px;
    h2{
      font-size:2rem;
    }
    h3{
      margin-top:15px;
    }
    h4{
      margin-bottom:15px;
    }
    ul{
      list-style: none;
      @include tablet{
        list-style-type: square;
        padding-left:20px;
      }
    }
  }

}

.not-found{
  display:flex;
  justify-content: center;
  align-items:center;
  height:100%;
  text-align:center;
  padding:100px;
  a{
    padding: 10px;
    color:white;
    background: #306f6f;
    margin-top:20px;
    display:block;
    text-decoration: none;
  }

}

.loading{
  display:flex;
  flex-direction:column;
  align-items:center;
  padding:50px;
}

footer{
  background:#666;
  color:white;
  font-weight:200;
  font-size:0.8rem;
  text-align:center;
  padding:5px;
  margin-top:auto;
  letter-spacing: 1px;
  a{
    color:#ddd;
  }
}


#link-to-portal{
  background: -webkit-linear-gradient(45deg, #1d3b6d, #018f5f);
  color: white;
  transition: transform 0.2s;
  &:hover{
    transform: scale(1.05) rotate(3deg);
  }
  a{
    color:white !important;
    font-weight:600;
  }
  padding: 10px;
  border-radius:5px;
}


.coming-soon{
  position: relative;
  &::before{
    content: "Coming soon!";
    display: block;
    position:absolute;
    background-color: #018f5f;
    padding:5px 10px;
    font-size:1.5rem;
    top:-15px;
    right: -40px;
  }
}

.container-tcs{
  text-align:left;
  h2:not(:first-child) {
    margin-top: 50px;
  }
  h2{
    display:inline-block;
  }
  p {
    font-size: 1.3rem;
    line-height: 1.3;
    margin-top: 5px;
  }

  span{
    margin-top:25px;
    display:block;
  }
}